export default {
  OPTIONS_LIST: "Settings list",

  MODULE: "module",
  NAME: "nom",
  VALUE: "Value",
  MODULE_GENERAL: "General",
  MODULE_PURCHASES: "Purchases",
  MODULE_SALES: "Sales",
  OPTION_DOCUMENT_FOOTER: "Document page",
  MODULE_PUBLIC_WEBSITE: "Public website",

  OPTION_DEFAULT_TAXES_GROUP: "Default tax group",
  OPTION_DEFAULT_PUBLIC_WEBSITE_LOGO: "Logo",
  OPTION_DEFAULT_PUBLIC_WEBSITE_COVER_IMAGE: "Cover Image",
  OPTION_DEFAULT_PUBLIC_WEBSITE_HOME_PAGE_DESIGN: "Home page design",

  HOME_PAGE_1: "Home page 1",
  HOME_PAGE_2: "Home page 2",
};
