import common from "./common";
import errors from "./errors";
import organizations from "./organizations";
import resellers from "./resellers";
import users from "./users";
import roles from "./roles";
import permissions from "./permissions";
import products from "./products";
import invoices from "./invoices";
import customers from "./customers";
import packages from "./packages";
import subscriptions from "./subscriptions";
import pricingRules from "./pricingRules";
import payments from "./payments";
import profile from "./profile";
import contacts from "@/i18n/en/contacts";
import suppliers from "@/i18n/en/suppliers";
import imports from "@/i18n/en/imports";
import properties from "./properties";
import auctions from "./auctions";
import propertyTransactions from "./propertyTransactions";
import spots from "./spots";
import spotCategories from "./spotCategories";
import equipmentCategories from "./equipmentCategories";
import equipments from "./equipments";
import configuration from "./configuration";
import spotTypes from "./spotTypes";
import bookings from "./bookings";
import options from "./options";
import salesOrders from "./salesOrders";
import validationMessages from 'vee-validate/dist/locale/en';

export default {
  COMMON: common,
  ERRORS: errors,
  USERS: users,
  ORGANIZATIONS: organizations,
  RESELLERS: resellers,
  ROLES: roles,
  PERMISSIONS: permissions,
  PRODUCTS: products,
  INVOICES: invoices,
  CUSTOMERS: customers,
  SUBSCRIPTIONS: subscriptions,
  PACKAGES: packages,
  PAYMENTS: payments,
  PROFILE: profile,
  CONTACTS: contacts,
  SUPPLIERS: suppliers,
  IMPORT: imports,
  PROPERTIES: properties,
  AUCTIONS: auctions,
  PROPERTY_TRANSACTIONS: propertyTransactions,
  SPOTS: spots,
  SPOT_CATEGORIES: spotCategories,
  EQUIPMENT_CATEGORIES: equipmentCategories,
  EQUIPMENTS: equipments,
  CONFIGURATION: configuration,
  SPOT_TYPES: spotTypes,
  BOOKINGS: bookings,
  OPTIONS: options,
  PRICING_RULES: pricingRules,
  SALES_ORDERS: salesOrders,
  validations: {
    ...validationMessages
  }
};
