export default {
  CUSTOMERS_LIST: "Customers list",
  ADD_CUSTOMER: "Add customer",
  ADD_CONTACT: "Add contact",
  CUSTOMER_ADDED: "Customer added",
  EDIT_CUSTOMER: "Edit customer",
  CUSTOMER_UPDATED: "Customer updated",
  DELETE_THIS_CUSTOMER: "Delete this customer?",
  CUSTOMER_DELETED: "Customer deleted",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Same as customer's address",
  CUSTOMER_TYPE_INDIVIDUAL: "Individual",
  CUSTOMER_TYPE_COMPANY: "Company",
  LAST_NAME: "Last name",
  FIRST_NAME: "First name",
  EMAIL: "Email",
  EMAIL_SHORT: "Email",
  PHONE: "Phone",
  PHONE_SHORT: "Phone",
  COUNTRY: "Country",
  ADDRESS: "Address",
  CUSTOMER_DETAILS: "Customer detail",
  CUSTOMER_INFORMATION: "Customer information",
  PAYMENT_METHOD_MANAGES: "Manage payment methods",
  PAYMENT_METHODS_PAYMENTS: "View associated payments"
};
