export default {
  SPOT_CATEGORY_LIST: "Category list",
  ADD_SPOT_CATEGORY: "Add category",
  SPOT_CATEGORY_ADDED: "Category added",
  EDIT_SPOT_CATEGORY: "Update category",
  SPOT_CATEGORY_UPDATED: "Category updated",
  DELETE_THIS_SPOT_CATEGORY: "delete category ?",
  SPOT_CATEGORY_DELETED: "Category deleted",
  SPOT_CATEGORY_NAME: "Category name",
  SPOT_FORM_TYPE: "Spot form type",
  SPOT_FORM_TYPE_DEFAULT: "Default",
  SPOT_FORM_TYPE_LAND: "Land",
  SPOT_FORM_TYPE_PROPERTY: "Property",
  SPOT_FORM_TYPE_SEASONAL: "Seasonal",
  VIEW_SPOT_CATEGORY: "Category details",
  SAME_SPOT_CATEGORIES:
    'One or more spot categories already exist with the name {name}. Is this the same category? Click "Continue" to save if so, otherwise click "Cancel."',
  BOOKING_FORM_TYPE: "Booking form type",
  BOOKING_FORM_TYPE_DEFAULT: "Default",
  BOOKING_FORM_TYPE_LAND: "Land",
  BOOKING_FORM_TYPE_PROPERTY: "Property",
  BOOKING_FORM_TYPE_SEASONAL: "Seasonal",
  BOOKING_FORM_TYPE_READY_TO_CAMP: "Ready to camp",
};
