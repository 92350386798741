export default {
  EQUIPMENT_LIST: "Service list",
  ADD_EQUIPMENT: "Add service",
  EQUIPMENT_ADDED: "Service added",
  EDIT_EQUIPMENT: "Update service",
  EQUIPMENT_UPDATED: "Service updated",
  DELETE_THIS_EQUIPMENT: "delete service ?",
  EQUIPMENT_DELETED: "Service deleted",
  EQUIPMENT_NAME: "Service name",
  VIEW_EQUIPMENT: "Service details",
  EQUIPMENT_CATEGORY_NAME: "Service category",
  SAME_EQUIPMENTS:
    'One or more service already exist with the name {name}. Is this the same service? Click "Continue" to save if so, otherwise click "Cancel."',
  DETAILS_VIEW_EQUIPMENT: "Service details",
  NO_DATA:
      "You have not added any services yet, click on the button to add.",
  EQUIPMENT_INFORMATIONS: "Informations about the service",
};
