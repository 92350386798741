export default {
  SALES_ORDERS_LIST: "List of orders",
  ADD_SALES_ORDER: "Add order",
  ADD_SALES_ORDER_ITEM: "Add line",
  SALES_ORDER_ADDED: "Order added",
  OWNER: "Owner",
  EDIT_SALES_ORDER: "Edit order",
  SALES_ORDER_UPDATED: "Order updated",
  BILLING_ADDRESS: "Billing address",
  EXPIRATION_TIME: "Expiration date",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Select product",
  SELECT_SUBSCRIPTIONS: "Select subscription",
  SELECT_PACKAGES: "Select package",
  DELETE_SALES_ORDER_ITEM: "Delete line",
  UPDATE_SALES_ORDER_ITEM: "Edit line",
  SALES_ORDER_ITEM_DELETED: "Line deleted",
  SALES_ORDER_ITEM_ADDED: "Line added",
  SALES_ORDER_ITEM_EDITED: "Line edited",
  SEND: "Send",
  CANCEL: "Cancel",
  REFUND: "Refund",
  ADD_PAYMENT: "Add payment",
  SALES_ORDER_SENT: "Order sent",
  SELECT_USERS: "Select user",
  SELECT_CUSTOMERS: "Select customer",
  SELECT_ORGANIZATIONS: "Select campsite",
  SELECT_SUPPLIERS: "Select supplier",
  DELETE_THIS_SALES_ORDER: "Delete this order?",
  SALES_ORDER_DELETED: "Order deleted",
  CANCEL_SALES_ORDER_QUESTION: "Cancel order?",
  SALES_ORDER_CANCELED: "Order Cancelled",
  RECIPIENT: "Recipient",
  ISSUER: "Issuer",
  VALIDATE: "Validate",
  SALES_ORDER_VALIDATED: "Order Validated",
  GENERATE_INVOICE: "Create Invoice",
  INVOICE_GENERATED: "Invoice Created",
  VIEW_INVOICE: "View Invoice",
  ADD_DELIVERY: "Add Delivery",
  DELIVERIES_STATE: "Delivery Status",
  DELIVERIES_STATE_ITEM: "Product",
  DELIVERIES_STATE_ORDERED_QUANTITY: "Quantity Ordered",
  DELIVERIES_STATE_DELIVERED_QUANTITY: "Quantity Delivered",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Remaining Quantity",
  NOT_INVOICED: "To Invoice",
  NOT_DELIVERED: "To Deliver",
  DELIVERED: "Delivered",
  SALES_INVOICES: "Sales Invoices",
  ADD_INVOICE: "Add Invoice",
  INVOICING_TYPE: "Invoice Type",
  INVOICING_TYPE_COMPLETE: "Complete",
  INVOICING_TYPE_AMOUNT: "Amount",
  INVOICING_TYPE_PRODUCT: "Product",
  INVOICING_STATUS_PENDING: "Not Invoiced",
  INVOICING_STATUS_PARTIALLY_INVOICED: "Partially Invoiced",
  INVOICING_STATUS_INVOICED: "Invoiced",
  DELIVERY_STATUS_PENDING: "Not Delivered",
  DELIVERY_STATUS_PARTIALLY_DELIVERED: "Partially Delivered",
  DELIVERY_STATUS_DELIVERED: "Delivered",
  INVOICING_STATUS: "Billing Status",
  ORDER_STATUS_DRAFT: "Draft",
  ORDER_STATUS_CANCELED: "Canceled",
  ORDER_STATUS_VALIDATED: "Validated",
  ORDER_STATUS_COMPLETED: "Completed",
  DELIVERY_STATUS: "Delivery status",
  INVOICE_TYPE: "Invoice type",
  INVOICE_TYPE_DOWNPAYMENT: "Downpayment invoice",
  INVOICE_TYPE_STANDARD: "Standard invoice",
  SALES_ORDER_INVOICE_ADDED: "Invoice added",
  VIEW_SALES_ORDER: "Purchase order",
  ORDER_PERIOD_TODAY: "Today",
  ORDER_PERIOD_YESTERDAY: "Yesterday",
  ORDER_PERIOD_CURRENT_WEEK: "This week",
  ORDER_PERIOD_CURRENT_MONTH: "This month",
  ORDER_PERIOD_LAST_THREE_MONTHS: "Last three months",
  ORDER_PERIOD_LAST_SEVEN_DAYS: "Last seven days",
};
