export default {
  EQUIPMENT_CATEGORY_LIST: "Category list",
  ADD_EQUIPMENT_CATEGORY: "Add category",
  EQUIPMENT_CATEGORY_ADDED: "Category added",
  EDIT_EQUIPMENT_CATEGORY: "Update category",
  EQUIPMENT_CATEGORY_UPDATED: "Category updated",
  DELETE_THIS_EQUIPMENT_CATEGORY: "delete category ?",
  EQUIPMENT_CATEGORY_DELETED: "Category deleted",
  EQUIPMENT_CATEGORY_NAME: "Category name",
  VIEW_EQUIPMENT_CATEGORY: "Category details",
  SAME_EQUIPMENT_CATEGORIES:
    'One or more service categories already exist with the name {name}. Is this the same category? Click "Continue" to save if so, otherwise click "Cancel."',
};
